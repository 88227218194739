/*** General styles ***/
@import './variables.scss';

h2 {
    color: $default-black;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Roboto, sans-serif;
    font-size: 3rem;
    line-height: 1.2;
}

h3 {
    color: $default-black;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Roboto, sans-serif;
    font-size: 2rem;
    line-height: 1.2;
}

h4 {
    color: $default-black;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.2;
}

a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 24px;
    z-index: 1000;
    pointer-events: none;
}



.black-btn {
    background-color: #000;
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    padding: 9px 15px;
    display: inline-block;
    text-align: center;
    border: 1px solid #000;
    border-radius: .5rem;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 500;
}

.negative-btn {
    background-color: transparent;
    color: $default-black;
    line-height: inherit;
    cursor: pointer;
    padding: 9px 15px;
    display: inline-block;
    text-align: center;
    border: 1px solid $default-black;
    border-radius: 0.5rem;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 500;

    &:hover {
        background-color: $grey-lighten-2;
    }
}

.disabled-btn {
    background-color: #888;
    /* Lighter color to indicate disabled state */
    color: #ccc;
    /* Light gray color for text */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    border: 1px solid #888;
    /* Match border color to background */
}

.text-red {
    color: $red-darken-1;
}

.text-color-gov {
    color: $secondary;
}

.heading-style-h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
}

.heading-style-h4 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
}

.heading-style-h5 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.4;
}

.paragraph,
.paragraph-2 {
    text-align: justify;
}

.terms-declaration {
    padding: 15px 10px;
    margin: 8px 0;
    border: 1px solid $grey-lighten-2;
    display: flex;
    align-items: center;

    input {
        margin-left: 15px;
        border: 1px solid $grey-lighten-2;
    }

    p {
        padding-top: 10px;
        margin-left: 15px;
        color: $grey-darken-1;
        font-size: $font-size-body-1;
    }
}