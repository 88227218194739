/* You can add global styles to this file, and also import other style files */
$spacer: 16px;
@import "bootstrap";
@import "./assets/variables.scss";
@import "./assets/general.scss";
@import "./assets/documents-upload.scss";

// accessibility
@import "./assets/accessibility-tools.scss";

// bootstrap overides
ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 16px;
}

.row {
    --bs-gutter-x: 16px;
}

.w-100 {
    width: 100%;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    --bs-gutter-x: 24px;
}

.mat-mdc-form-field-infix {
    margin-top: 6px !important;
}

.mat-body,
.mat-body-2,
.mat-typography {
    letter-spacing: 0.28px;
}

.mat-mdc-dialog-container {
    --mdc-dialog-subhead-tracking: 0.2px;
    --mdc-dialog-supporting-text-tracking: 0.5px;
}

.form-control {
    padding: 6px 12px;
    font-size: 16px;
}

// Button classes
.btn-sm,
.btn-group-sm,
.btn {
    --bs-btn-padding-y: 4px;
    --bs-btn-padding-x: 8px;
    --bs-btn-font-size: 14px;
    --bs-border-radius-sm: 4px;
    --bs-btn-focus-box-shadow: 0 0 0 4px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Material overrides

.mat-mdc-card {
    /* Override with rem values */
    --mat-card-title-text-line-height: 32px;
    /* 32px / 16px */
    --mat-card-title-text-size: 20px;
    /* 20px / 16px */

    /* For em values, you must know the font size of the element to calculate the correct value. 
     Assuming the font-size of the card is 16px (which is 1em), the calculations are the same as for rem */
    --mat-card-subtitle-text-line-height: 22px;
    /* 22px / 16px */
    --mat-card-subtitle-text-size: 14px;
    /* 14px / 16px */

    /* For letter-spacing, convert em to px by multiplying with the current font-size.
     Since 0.0125em is the tracking for the title and 0.0071428571em for the subtitle,
     you need to know the font-size of the title and subtitle to convert these values to pixels. 
     If we assume that title's font-size is 20px and subtitle's font-size is 14px: */
    --mat-card-title-text-tracking: 0.25px;
    /* 0.0125em * 20px */
    --mat-card-subtitle-text-tracking: 0.1px;
    /* 0.0071428571em * 14px */

    /* You typically don't need to convert font-weight, as it's a unitless value. */
    --mat-card-title-text-weight: 500;
    --mat-card-subtitle-text-weight: 500;
}

.mat-typography {
    letter-spacing: 0.2857142864px;
}

p {
    margin-block-start: 16px;
    margin-block-end: 16px;
    font-size: 16px;
}

// Swal overrides

.swal2-confirm.swal2-styled {
    text-decoration: none;
    border-radius: .5rem;
    cursor: pointer;
    background-color: $default-black;
    font-size: 1rem;
    font-weight: 500;
    padding: 9px 15px;
}

// Hides the white backgroud of the Dialog when added to the MatDialog's config object
.custom-modalbox {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
        background-color: transparent !important;
        box-shadow: none;
    }
}

// Adds scrollbal-gutter to the Dialog when added to the MatDialog's config object
.scroll-modalbox {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
        scrollbar-gutter: stable;
    }
}

.swal2-popup {
    font-size: $font-size-body-2 !important;
}

.section-spinner {
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & .mat-mdc-progress-spinner {
        --mdc-circular-progress-active-indicator-color: white;
    }

    & .spinner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        align-items: center;
    }
}

@media only screen and (max-width: 561px) {
    .footer-bar {
        display: flex;
        flex-direction: column;
    }

}

@media only screen and (max-width: 1152px) {
    
}


@media only screen and (max-width: 864px) {
    
}

@media only screen and (max-width: 580px) {
    
}

@media only screen and (max-width: 540px) {
    
}

@media only screen and (max-width: 720px) {
    
}