@import "./variables.scss";

#pojo-a11y-toolbar {
    position: fixed;
    font-size: 16px !important;
    line-height: 1.4;
    z-index: 9999;
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-right {
    right: -180px;
    -webkit-transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-right.pojo-a11y-toolbar-open {
    right: 0;
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-right .pojo-a11y-toolbar-toggle {
    right: 180px;
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-left {
    left: -180px;
    -webkit-transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-left.pojo-a11y-toolbar-open {
    left: 0;
}

#pojo-a11y-toolbar.pojo-a11y-toolbar-left .pojo-a11y-toolbar-toggle {
    left: 180px;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-toggle {
    position: absolute;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-toggle a {
    display: inline-block;
    font-size: 200%;
    line-height: 0;
    padding: 10px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #4054b2;
    color: $default-white;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-toggle a:hover,
#pojo-a11y-toolbar .pojo-a11y-toolbar-toggle a:focus {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #263eac;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-toggle svg {
    max-width: inherit;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay {
    border: 1px solid;
    font-size: 100%;
    width: 180px;
    background-color: $default-white;
    border-color: #4054b2;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay p.pojo-a11y-toolbar-title {
    display: block;
    line-height: 2;
    font-weight: bold;
    padding: 10px 15px 0;
    margin: 0;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay.pojo-a11y-toolbar-open .pojo-a11y-toolbar-toggle a,
#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay.pojo-a11y-toolbar-open .pojo-a11y-toolbar-overlay {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items.pojo-a11y-links {
    border-top: 1px solid;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a {
    display: block;
    padding: 10px 15px;
    font-size: 80%;
    line-height: 1;
    cursor: pointer;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a.active {
    font-weight: bold;
    background-color: #4054b2;
    color: $default-white;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a svg {
    padding-right: 6px;
    display: inline-block;
    width: 1.5em;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    line-height: 1;
    text-align: center;
    text-rendering: auto;
    vertical-align: middle;
}

#pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a .pojo-a11y-toolbar-text {
    vertical-align: middle;
}

body.rtl #pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a svg {
    padding-left: 6px;
    padding-right: 0;
}

@media (max-width: 767px) {
    #pojo-a11y-toolbar .pojo-a11y-toolbar-overlay p.pojo-a11y-toolbar-title {
        padding: 7px 12px 0;
    }

    #pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items {
        padding: 7px 0;
    }

    #pojo-a11y-toolbar .pojo-a11y-toolbar-overlay ul.pojo-a11y-toolbar-items li.pojo-a11y-toolbar-item a {
        display: block;
        padding: 7px 12px;
    }
}

body.pojo-a11y-grayscale {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray;
}

body.pojo-a11y-high-contrast {
    /* This stylesheet based on 0WonB.css generated by Accessibility CSS Generator, (c) Silas S. Brown 2006-2015.  Version 0.9844 */
}

body.pojo-a11y-high-contrast .placebo {
    line-height: normal;
}

body.pojo-a11y-high-contrast * {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

body.pojo-a11y-high-contrast a,
body.pojo-a11y-high-contrast abbr,
body.pojo-a11y-high-contrast acronym,
body.pojo-a11y-high-contrast address,
body.pojo-a11y-high-contrast article,
body.pojo-a11y-high-contrast aside,
body.pojo-a11y-high-contrast b,
body.pojo-a11y-high-contrast basefont,
body.pojo-a11y-high-contrast bdi,
body.pojo-a11y-high-contrast big,
body.pojo-a11y-high-contrast blink,
body.pojo-a11y-high-contrast blockquote,
body.pojo-a11y-high-contrast body,
body.pojo-a11y-high-contrast button,
body.pojo-a11y-high-contrast canvas,
body.pojo-a11y-high-contrast caption,
body.pojo-a11y-high-contrast center,
body.pojo-a11y-high-contrast cite,
body.pojo-a11y-high-contrast code,
body.pojo-a11y-high-contrast col,
body.pojo-a11y-high-contrast colgroup,
body.pojo-a11y-high-contrast command,
body.pojo-a11y-high-contrast dd,
body.pojo-a11y-high-contrast del,
body.pojo-a11y-high-contrast details,
body.pojo-a11y-high-contrast dfn,
body.pojo-a11y-high-contrast dir,
body.pojo-a11y-high-contrast div,
body.pojo-a11y-high-contrast dl,
body.pojo-a11y-high-contrast dt,
body.pojo-a11y-high-contrast em,
body.pojo-a11y-high-contrast embed,
body.pojo-a11y-high-contrast fieldset,
body.pojo-a11y-high-contrast figcaption,
body.pojo-a11y-high-contrast figure,
body.pojo-a11y-high-contrast font,
body.pojo-a11y-high-contrast footer,
body.pojo-a11y-high-contrast form,
body.pojo-a11y-high-contrast h1,
body.pojo-a11y-high-contrast h1 a,
body.pojo-a11y-high-contrast h1 a b,
body.pojo-a11y-high-contrast h1 abbr,
body.pojo-a11y-high-contrast h1 b,
body.pojo-a11y-high-contrast h1 center,
body.pojo-a11y-high-contrast h1 em,
body.pojo-a11y-high-contrast h1 i,
body.pojo-a11y-high-contrast h1 span,
body.pojo-a11y-high-contrast h1 strong,
body.pojo-a11y-high-contrast h2,
body.pojo-a11y-high-contrast h2 a,
body.pojo-a11y-high-contrast h2 a b,
body.pojo-a11y-high-contrast h2 abbr,
body.pojo-a11y-high-contrast h2 b,
body.pojo-a11y-high-contrast h2 center,
body.pojo-a11y-high-contrast h2 em,
body.pojo-a11y-high-contrast h2 i,
body.pojo-a11y-high-contrast h2 span,
body.pojo-a11y-high-contrast h2 strong,
body.pojo-a11y-high-contrast h3,
body.pojo-a11y-high-contrast h3 a,
body.pojo-a11y-high-contrast h3 a b,
body.pojo-a11y-high-contrast h3 abbr,
body.pojo-a11y-high-contrast h3 b,
body.pojo-a11y-high-contrast h3 center,
body.pojo-a11y-high-contrast h3 em,
body.pojo-a11y-high-contrast h3 i,
body.pojo-a11y-high-contrast h3 span,
body.pojo-a11y-high-contrast h3 strong,
body.pojo-a11y-high-contrast h4,
body.pojo-a11y-high-contrast h4 a,
body.pojo-a11y-high-contrast h4 a b,
body.pojo-a11y-high-contrast h4 abbr,
body.pojo-a11y-high-contrast h4 b,
body.pojo-a11y-high-contrast h4 center,
body.pojo-a11y-high-contrast h4 em,
body.pojo-a11y-high-contrast h4 i,
body.pojo-a11y-high-contrast h4 span,
body.pojo-a11y-high-contrast h4 strong,
body.pojo-a11y-high-contrast h5,
body.pojo-a11y-high-contrast h5 a,
body.pojo-a11y-high-contrast h5 a b,
body.pojo-a11y-high-contrast h5 abbr,
body.pojo-a11y-high-contrast h5 b,
body.pojo-a11y-high-contrast h5 center,
body.pojo-a11y-high-contrast h5 em,
body.pojo-a11y-high-contrast h5 i,
body.pojo-a11y-high-contrast h5 span,
body.pojo-a11y-high-contrast h5 strong,
body.pojo-a11y-high-contrast h6,
body.pojo-a11y-high-contrast h6 a,
body.pojo-a11y-high-contrast h6 a b,
body.pojo-a11y-high-contrast h6 abbr,
body.pojo-a11y-high-contrast h6 b,
body.pojo-a11y-high-contrast h6 center,
body.pojo-a11y-high-contrast h6 em,
body.pojo-a11y-high-contrast h6 i,
body.pojo-a11y-high-contrast h6 span,
body.pojo-a11y-high-contrast h6 strong,
body.pojo-a11y-high-contrast header,
body.pojo-a11y-high-contrast hgroup,
body.pojo-a11y-high-contrast html,
body.pojo-a11y-high-contrast i,
body.pojo-a11y-high-contrast iframe,
body.pojo-a11y-high-contrast img,
body.pojo-a11y-high-contrast input,
body.pojo-a11y-high-contrast ins,
body.pojo-a11y-high-contrast kbd,
body.pojo-a11y-high-contrast label,
body.pojo-a11y-high-contrast legend,
body.pojo-a11y-high-contrast li,
body.pojo-a11y-high-contrast listing,
body.pojo-a11y-high-contrast main,
body.pojo-a11y-high-contrast mark,
body.pojo-a11y-high-contrast marquee,
body.pojo-a11y-high-contrast menu,
body.pojo-a11y-high-contrast meter,
body.pojo-a11y-high-contrast multicol,
body.pojo-a11y-high-contrast nav,
body.pojo-a11y-high-contrast nobr,
body.pojo-a11y-high-contrast object,
body.pojo-a11y-high-contrast ol,
body.pojo-a11y-high-contrast option,
body.pojo-a11y-high-contrast output,
body.pojo-a11y-high-contrast p,
body.pojo-a11y-high-contrast plaintext,
body.pojo-a11y-high-contrast pre,
body.pojo-a11y-high-contrast progress,
body.pojo-a11y-high-contrast q,
body.pojo-a11y-high-contrast rb,
body.pojo-a11y-high-contrast rp,
body.pojo-a11y-high-contrast rt,
body.pojo-a11y-high-contrast ruby,
body.pojo-a11y-high-contrast s,
body.pojo-a11y-high-contrast samp,
body.pojo-a11y-high-contrast section,
body.pojo-a11y-high-contrast select,
body.pojo-a11y-high-contrast small,
body.pojo-a11y-high-contrast span,
body.pojo-a11y-high-contrast strike,
body.pojo-a11y-high-contrast strong,
body.pojo-a11y-high-contrast sub,
body.pojo-a11y-high-contrast summary,
body.pojo-a11y-high-contrast sup,
body.pojo-a11y-high-contrast svg,
body.pojo-a11y-high-contrast table,
body.pojo-a11y-high-contrast tbody,
body.pojo-a11y-high-contrast td,
body.pojo-a11y-high-contrast text,
body.pojo-a11y-high-contrast textarea,
body.pojo-a11y-high-contrast th,
body.pojo-a11y-high-contrast thead,
body.pojo-a11y-high-contrast time,
body.pojo-a11y-high-contrast tr,
body.pojo-a11y-high-contrast tt,
body.pojo-a11y-high-contrast u,
body.pojo-a11y-high-contrast ul,
body.pojo-a11y-high-contrast var,
body.pojo-a11y-high-contrast video,
body.pojo-a11y-high-contrast xmp {
    -moz-appearance: none !important;
    -moz-user-select: text !important;
    -webkit-user-select: text !important;
    background-image: none !important;
    text-shadow: none !important;
    user-select: text !important;
}

body.pojo-a11y-high-contrast a,
body.pojo-a11y-high-contrast abbr,
body.pojo-a11y-high-contrast acronym,
body.pojo-a11y-high-contrast address,
body.pojo-a11y-high-contrast article,
body.pojo-a11y-high-contrast aside,
body.pojo-a11y-high-contrast b,
body.pojo-a11y-high-contrast basefont,
body.pojo-a11y-high-contrast bdi,
body.pojo-a11y-high-contrast big,
body.pojo-a11y-high-contrast blink,
body.pojo-a11y-high-contrast blockquote,
body.pojo-a11y-high-contrast body,
body.pojo-a11y-high-contrast canvas,
body.pojo-a11y-high-contrast caption,
body.pojo-a11y-high-contrast center,
body.pojo-a11y-high-contrast cite,
body.pojo-a11y-high-contrast code,
body.pojo-a11y-high-contrast col,
body.pojo-a11y-high-contrast colgroup,
body.pojo-a11y-high-contrast command,
body.pojo-a11y-high-contrast dd,
body.pojo-a11y-high-contrast del,
body.pojo-a11y-high-contrast details,
body.pojo-a11y-high-contrast dfn,
body.pojo-a11y-high-contrast dir,
body.pojo-a11y-high-contrast div,
body.pojo-a11y-high-contrast dl,
body.pojo-a11y-high-contrast dt,
body.pojo-a11y-high-contrast em,
body.pojo-a11y-high-contrast embed,
body.pojo-a11y-high-contrast fieldset,
body.pojo-a11y-high-contrast figcaption,
body.pojo-a11y-high-contrast figure,
body.pojo-a11y-high-contrast font,
body.pojo-a11y-high-contrast footer,
body.pojo-a11y-high-contrast form,
body.pojo-a11y-high-contrast h1,
body.pojo-a11y-high-contrast h1 a,
body.pojo-a11y-high-contrast h1 a b,
body.pojo-a11y-high-contrast h1 abbr,
body.pojo-a11y-high-contrast h1 b,
body.pojo-a11y-high-contrast h1 center,
body.pojo-a11y-high-contrast h1 em,
body.pojo-a11y-high-contrast h1 i,
body.pojo-a11y-high-contrast h1 span,
body.pojo-a11y-high-contrast h1 strong,
body.pojo-a11y-high-contrast h2,
body.pojo-a11y-high-contrast h2 a,
body.pojo-a11y-high-contrast h2 a b,
body.pojo-a11y-high-contrast h2 abbr,
body.pojo-a11y-high-contrast h2 b,
body.pojo-a11y-high-contrast h2 center,
body.pojo-a11y-high-contrast h2 em,
body.pojo-a11y-high-contrast h2 i,
body.pojo-a11y-high-contrast h2 span,
body.pojo-a11y-high-contrast h2 strong,
body.pojo-a11y-high-contrast h3,
body.pojo-a11y-high-contrast h3 a,
body.pojo-a11y-high-contrast h3 a b,
body.pojo-a11y-high-contrast h3 abbr,
body.pojo-a11y-high-contrast h3 b,
body.pojo-a11y-high-contrast h3 center,
body.pojo-a11y-high-contrast h3 em,
body.pojo-a11y-high-contrast h3 i,
body.pojo-a11y-high-contrast h3 span,
body.pojo-a11y-high-contrast h3 strong,
body.pojo-a11y-high-contrast h4,
body.pojo-a11y-high-contrast h4 a,
body.pojo-a11y-high-contrast h4 a b,
body.pojo-a11y-high-contrast h4 abbr,
body.pojo-a11y-high-contrast h4 b,
body.pojo-a11y-high-contrast h4 center,
body.pojo-a11y-high-contrast h4 em,
body.pojo-a11y-high-contrast h4 i,
body.pojo-a11y-high-contrast h4 span,
body.pojo-a11y-high-contrast h4 strong,
body.pojo-a11y-high-contrast h5,
body.pojo-a11y-high-contrast h5 a,
body.pojo-a11y-high-contrast h5 a b,
body.pojo-a11y-high-contrast h5 abbr,
body.pojo-a11y-high-contrast h5 b,
body.pojo-a11y-high-contrast h5 center,
body.pojo-a11y-high-contrast h5 em,
body.pojo-a11y-high-contrast h5 i,
body.pojo-a11y-high-contrast h5 span,
body.pojo-a11y-high-contrast h5 strong,
body.pojo-a11y-high-contrast h6,
body.pojo-a11y-high-contrast h6 a,
body.pojo-a11y-high-contrast h6 a b,
body.pojo-a11y-high-contrast h6 abbr,
body.pojo-a11y-high-contrast h6 b,
body.pojo-a11y-high-contrast h6 center,
body.pojo-a11y-high-contrast h6 em,
body.pojo-a11y-high-contrast h6 i,
body.pojo-a11y-high-contrast h6 span,
body.pojo-a11y-high-contrast h6 strong,
body.pojo-a11y-high-contrast header,
body.pojo-a11y-high-contrast hgroup,
body.pojo-a11y-high-contrast html,
body.pojo-a11y-high-contrast i,
body.pojo-a11y-high-contrast iframe,
body.pojo-a11y-high-contrast input,
body.pojo-a11y-high-contrast ins,
body.pojo-a11y-high-contrast kbd,
body.pojo-a11y-high-contrast label,
body.pojo-a11y-high-contrast legend,
body.pojo-a11y-high-contrast li,
body.pojo-a11y-high-contrast listing,
body.pojo-a11y-high-contrast main,
body.pojo-a11y-high-contrast mark,
body.pojo-a11y-high-contrast marquee,
body.pojo-a11y-high-contrast menu,
body.pojo-a11y-high-contrast meter,
body.pojo-a11y-high-contrast multicol,
body.pojo-a11y-high-contrast nav:not(#pojo-a11y-toolbar),
body.pojo-a11y-high-contrast nobr,
body.pojo-a11y-high-contrast object,
body.pojo-a11y-high-contrast ol,
body.pojo-a11y-high-contrast option,
body.pojo-a11y-high-contrast output,
body.pojo-a11y-high-contrast p,
body.pojo-a11y-high-contrast plaintext,
body.pojo-a11y-high-contrast pre,
body.pojo-a11y-high-contrast progress,
body.pojo-a11y-high-contrast q,
body.pojo-a11y-high-contrast rb,
body.pojo-a11y-high-contrast rp,
body.pojo-a11y-high-contrast rt,
body.pojo-a11y-high-contrast ruby,
body.pojo-a11y-high-contrast s,
body.pojo-a11y-high-contrast samp,
body.pojo-a11y-high-contrast section,
body.pojo-a11y-high-contrast small,
body.pojo-a11y-high-contrast span,
body.pojo-a11y-high-contrast strike,
body.pojo-a11y-high-contrast strong,
body.pojo-a11y-high-contrast sub,
body.pojo-a11y-high-contrast summary,
body.pojo-a11y-high-contrast sup,
body.pojo-a11y-high-contrast svg,
body.pojo-a11y-high-contrast table,
body.pojo-a11y-high-contrast tbody,
body.pojo-a11y-high-contrast td,
body.pojo-a11y-high-contrast text,
body.pojo-a11y-high-contrast textarea,
body.pojo-a11y-high-contrast th,
body.pojo-a11y-high-contrast thead,
body.pojo-a11y-high-contrast time,
body.pojo-a11y-high-contrast tr,
body.pojo-a11y-high-contrast tt,
body.pojo-a11y-high-contrast u,
body.pojo-a11y-high-contrast ul,
body.pojo-a11y-high-contrast var,
body.pojo-a11y-high-contrast video,
body.pojo-a11y-high-contrast xmp {
    background: black !important;
    background-color: black !important;
}

body.pojo-a11y-high-contrast a,
body.pojo-a11y-high-contrast article,
body.pojo-a11y-high-contrast aside,
body.pojo-a11y-high-contrast basefont,
body.pojo-a11y-high-contrast bdi,
body.pojo-a11y-high-contrast big,
body.pojo-a11y-high-contrast blink,
body.pojo-a11y-high-contrast blockquote,
body.pojo-a11y-high-contrast body,
body.pojo-a11y-high-contrast button,
body.pojo-a11y-high-contrast canvas,
body.pojo-a11y-high-contrast caption,
body.pojo-a11y-high-contrast center,
body.pojo-a11y-high-contrast code,
body.pojo-a11y-high-contrast col,
body.pojo-a11y-high-contrast colgroup,
body.pojo-a11y-high-contrast command,
body.pojo-a11y-high-contrast dd,
body.pojo-a11y-high-contrast del,
body.pojo-a11y-high-contrast details,
body.pojo-a11y-high-contrast dir,
body.pojo-a11y-high-contrast div,
body.pojo-a11y-high-contrast dl,
body.pojo-a11y-high-contrast dt,
body.pojo-a11y-high-contrast embed,
body.pojo-a11y-high-contrast fieldset,
body.pojo-a11y-high-contrast figcaption,
body.pojo-a11y-high-contrast figure,
body.pojo-a11y-high-contrast font,
body.pojo-a11y-high-contrast footer,
body.pojo-a11y-high-contrast form,
body.pojo-a11y-high-contrast header,
body.pojo-a11y-high-contrast hgroup,
body.pojo-a11y-high-contrast html,
body.pojo-a11y-high-contrast iframe,
body.pojo-a11y-high-contrast img,
body.pojo-a11y-high-contrast input,
body.pojo-a11y-high-contrast ins,
body.pojo-a11y-high-contrast kbd,
body.pojo-a11y-high-contrast label,
body.pojo-a11y-high-contrast legend,
body.pojo-a11y-high-contrast li,
body.pojo-a11y-high-contrast listing,
body.pojo-a11y-high-contrast main,
body.pojo-a11y-high-contrast mark,
body.pojo-a11y-high-contrast marquee,
body.pojo-a11y-high-contrast menu,
body.pojo-a11y-high-contrast meter,
body.pojo-a11y-high-contrast multicol,
body.pojo-a11y-high-contrast nav,
body.pojo-a11y-high-contrast nobr,
body.pojo-a11y-high-contrast object,
body.pojo-a11y-high-contrast ol,
body.pojo-a11y-high-contrast option,
body.pojo-a11y-high-contrast output,
body.pojo-a11y-high-contrast p,
body.pojo-a11y-high-contrast plaintext,
body.pojo-a11y-high-contrast pre,
body.pojo-a11y-high-contrast progress,
body.pojo-a11y-high-contrast q,
body.pojo-a11y-high-contrast rb,
body.pojo-a11y-high-contrast rp,
body.pojo-a11y-high-contrast rt,
body.pojo-a11y-high-contrast ruby,
body.pojo-a11y-high-contrast s,
body.pojo-a11y-high-contrast samp,
body.pojo-a11y-high-contrast section,
body.pojo-a11y-high-contrast select,
body.pojo-a11y-high-contrast small,
body.pojo-a11y-high-contrast span,
body.pojo-a11y-high-contrast strike,
body.pojo-a11y-high-contrast sub,
body.pojo-a11y-high-contrast summary,
body.pojo-a11y-high-contrast sup,
body.pojo-a11y-high-contrast svg,
body.pojo-a11y-high-contrast table,
body.pojo-a11y-high-contrast tbody,
body.pojo-a11y-high-contrast td,
body.pojo-a11y-high-contrast text,
body.pojo-a11y-high-contrast textarea,
body.pojo-a11y-high-contrast th,
body.pojo-a11y-high-contrast thead,
body.pojo-a11y-high-contrast time,
body.pojo-a11y-high-contrast tr,
body.pojo-a11y-high-contrast tt,
body.pojo-a11y-high-contrast ul,
body.pojo-a11y-high-contrast var,
body.pojo-a11y-high-contrast video,
body.pojo-a11y-high-contrast xmp {
    color: white !important;
}

body.pojo-a11y-high-contrast abbr,
body.pojo-a11y-high-contrast acronym,
body.pojo-a11y-high-contrast b,
body.pojo-a11y-high-contrast b span,
body.pojo-a11y-high-contrast h1 b,
body.pojo-a11y-high-contrast h1 strong,
body.pojo-a11y-high-contrast h2 b,
body.pojo-a11y-high-contrast h2 strong,
body.pojo-a11y-high-contrast h3 b,
body.pojo-a11y-high-contrast h3 strong,
body.pojo-a11y-high-contrast h4 b,
body.pojo-a11y-high-contrast h4 strong,
body.pojo-a11y-high-contrast h5 b,
body.pojo-a11y-high-contrast h5 strong,
body.pojo-a11y-high-contrast h6 b,
body.pojo-a11y-high-contrast h6 strong,
body.pojo-a11y-high-contrast strong,
body.pojo-a11y-high-contrast strong span {
    color: yellow !important;
}

body.pojo-a11y-high-contrast address,
body.pojo-a11y-high-contrast address span,
body.pojo-a11y-high-contrast cite,
body.pojo-a11y-high-contrast cite span,
body.pojo-a11y-high-contrast dfn,
body.pojo-a11y-high-contrast dfn span,
body.pojo-a11y-high-contrast em,
body.pojo-a11y-high-contrast em span,
body.pojo-a11y-high-contrast h1 em,
body.pojo-a11y-high-contrast h1 i,
body.pojo-a11y-high-contrast h2 em,
body.pojo-a11y-high-contrast h2 i,
body.pojo-a11y-high-contrast h3 em,
body.pojo-a11y-high-contrast h3 i,
body.pojo-a11y-high-contrast h4 em,
body.pojo-a11y-high-contrast h4 i,
body.pojo-a11y-high-contrast h5 em,
body.pojo-a11y-high-contrast h5 i,
body.pojo-a11y-high-contrast h6 em,
body.pojo-a11y-high-contrast h6 i,
body.pojo-a11y-high-contrast i,
body.pojo-a11y-high-contrast i span,
body.pojo-a11y-high-contrast u,
body.pojo-a11y-high-contrast u span {
    color: #FFFF80 !important;
}

body.pojo-a11y-high-contrast dt {
    border-top: thin solid grey !important;
}

body.pojo-a11y-high-contrast h1,
body.pojo-a11y-high-contrast h1 a,
body.pojo-a11y-high-contrast h1 a b,
body.pojo-a11y-high-contrast h1 abbr,
body.pojo-a11y-high-contrast h1 center,
body.pojo-a11y-high-contrast h1 span,
body.pojo-a11y-high-contrast h2,
body.pojo-a11y-high-contrast h2 a,
body.pojo-a11y-high-contrast h2 a b,
body.pojo-a11y-high-contrast h2 abbr,
body.pojo-a11y-high-contrast h2 center,
body.pojo-a11y-high-contrast h2 span,
body.pojo-a11y-high-contrast h3,
body.pojo-a11y-high-contrast h3 a,
body.pojo-a11y-high-contrast h3 a b,
body.pojo-a11y-high-contrast h3 abbr,
body.pojo-a11y-high-contrast h3 center,
body.pojo-a11y-high-contrast h3 span,
body.pojo-a11y-high-contrast h4,
body.pojo-a11y-high-contrast h4 a,
body.pojo-a11y-high-contrast h4 a b,
body.pojo-a11y-high-contrast h4 abbr,
body.pojo-a11y-high-contrast h4 center,
body.pojo-a11y-high-contrast h4 span,
body.pojo-a11y-high-contrast h5,
body.pojo-a11y-high-contrast h5 a,
body.pojo-a11y-high-contrast h5 a b,
body.pojo-a11y-high-contrast h5 abbr,
body.pojo-a11y-high-contrast h5 center,
body.pojo-a11y-high-contrast h5 span,
body.pojo-a11y-high-contrast h6,
body.pojo-a11y-high-contrast h6 a,
body.pojo-a11y-high-contrast h6 a b,
body.pojo-a11y-high-contrast h6 abbr,
body.pojo-a11y-high-contrast h6 center,
body.pojo-a11y-high-contrast h6 span {
    color: #40C090 !important;
}

body.pojo-a11y-high-contrast img {
    background: #808080 !important;
    background-color: #808080 !important;
}

body.pojo-a11y-high-contrast abbr,
body.pojo-a11y-high-contrast acronym {
    border-bottom: 1px dotted !important;
}

body.pojo-a11y-high-contrast :focus {
    outline: thin dotted !important;
}

body.pojo-a11y-high-contrast a.button,
body.pojo-a11y-high-contrast a.button abbr,
body.pojo-a11y-high-contrast a.button acronym,
body.pojo-a11y-high-contrast a.button b,
body.pojo-a11y-high-contrast a.button basefont,
body.pojo-a11y-high-contrast a.button big,
body.pojo-a11y-high-contrast a.button br,
body.pojo-a11y-high-contrast a.button code,
body.pojo-a11y-high-contrast a.button div,
body.pojo-a11y-high-contrast a.button em,
body.pojo-a11y-high-contrast a.button font,
body.pojo-a11y-high-contrast a.button h1,
body.pojo-a11y-high-contrast a.button h2,
body.pojo-a11y-high-contrast a.button h3,
body.pojo-a11y-high-contrast a.button h4,
body.pojo-a11y-high-contrast a.button h5,
body.pojo-a11y-high-contrast a.button h6,
body.pojo-a11y-high-contrast a.button i,
body.pojo-a11y-high-contrast a.button kbd,
body.pojo-a11y-high-contrast a.button rb,
body.pojo-a11y-high-contrast a.button rp,
body.pojo-a11y-high-contrast a.button rt,
body.pojo-a11y-high-contrast a.button ruby,
body.pojo-a11y-high-contrast a.button samp,
body.pojo-a11y-high-contrast a.button small,
body.pojo-a11y-high-contrast a.button span,
body.pojo-a11y-high-contrast a.button strong,
body.pojo-a11y-high-contrast a.button tt,
body.pojo-a11y-high-contrast a.button u,
body.pojo-a11y-high-contrast a.button var,
body.pojo-a11y-high-contrast a:link,
body.pojo-a11y-high-contrast a:link abbr,
body.pojo-a11y-high-contrast a:link acronym,
body.pojo-a11y-high-contrast a:link b,
body.pojo-a11y-high-contrast a:link basefont,
body.pojo-a11y-high-contrast a:link big,
body.pojo-a11y-high-contrast a:link br,
body.pojo-a11y-high-contrast a:link code,
body.pojo-a11y-high-contrast a:link div,
body.pojo-a11y-high-contrast a:link em,
body.pojo-a11y-high-contrast a:link font,
body.pojo-a11y-high-contrast a:link h1,
body.pojo-a11y-high-contrast a:link h2,
body.pojo-a11y-high-contrast a:link h3,
body.pojo-a11y-high-contrast a:link h4,
body.pojo-a11y-high-contrast a:link h5,
body.pojo-a11y-high-contrast a:link h6,
body.pojo-a11y-high-contrast a:link i,
body.pojo-a11y-high-contrast a:link kbd,
body.pojo-a11y-high-contrast a:link rb,
body.pojo-a11y-high-contrast a:link rp,
body.pojo-a11y-high-contrast a:link rt,
body.pojo-a11y-high-contrast a:link ruby,
body.pojo-a11y-high-contrast a:link samp,
body.pojo-a11y-high-contrast a:link small,
body.pojo-a11y-high-contrast a:link span,
body.pojo-a11y-high-contrast a:link strong,
body.pojo-a11y-high-contrast a:link tt,
body.pojo-a11y-high-contrast a:link u,
body.pojo-a11y-high-contrast a:link var,
body.pojo-a11y-high-contrast div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem {
    color: #0080FF !important;
}

body.pojo-a11y-high-contrast button,
body.pojo-a11y-high-contrast input,
body.pojo-a11y-high-contrast textarea,
body.pojo-a11y-high-contrast select,
body.pojo-a11y-high-contrast table,
body.pojo-a11y-high-contrast td,
body.pojo-a11y-high-contrast th,
body.pojo-a11y-high-contrast tr,
body.pojo-a11y-high-contrast tt {
    border: 1px solid #ffffff !important;
}

body.pojo-a11y-high-contrast button {
    background: #600040 !important;
    background-color: #600040 !important;
}

body.pojo-a11y-high-contrast select {
    -webkit-appearance: listbox !important;
    background: #600060 !important;
    background-color: #600060 !important;
}

body.pojo-a11y-high-contrast a:visited,
body.pojo-a11y-high-contrast a:visited abbr,
body.pojo-a11y-high-contrast a:visited acronym,
body.pojo-a11y-high-contrast a:visited b,
body.pojo-a11y-high-contrast a:visited basefont,
body.pojo-a11y-high-contrast a:visited big,
body.pojo-a11y-high-contrast a:visited br,
body.pojo-a11y-high-contrast a:visited code,
body.pojo-a11y-high-contrast a:visited div,
body.pojo-a11y-high-contrast a:visited em,
body.pojo-a11y-high-contrast a:visited font,
body.pojo-a11y-high-contrast a:visited h1,
body.pojo-a11y-high-contrast a:visited h2,
body.pojo-a11y-high-contrast a:visited h3,
body.pojo-a11y-high-contrast a:visited h4,
body.pojo-a11y-high-contrast a:visited h5,
body.pojo-a11y-high-contrast a:visited h6,
body.pojo-a11y-high-contrast a:visited i,
body.pojo-a11y-high-contrast a:visited kbd,
body.pojo-a11y-high-contrast a:visited rb,
body.pojo-a11y-high-contrast a:visited rp,
body.pojo-a11y-high-contrast a:visited rt,
body.pojo-a11y-high-contrast a:visited ruby,
body.pojo-a11y-high-contrast a:visited samp,
body.pojo-a11y-high-contrast a:visited small,
body.pojo-a11y-high-contrast a:visited span,
body.pojo-a11y-high-contrast a:visited strong,
body.pojo-a11y-high-contrast a:visited tt,
body.pojo-a11y-high-contrast a:visited u,
body.pojo-a11y-high-contrast a:visited var,
body.pojo-a11y-high-contrast div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem.active {
    color: #00FFFF !important;
}

body.pojo-a11y-high-contrast ::selection,
body.pojo-a11y-high-contrast ::-moz-selection {
    background: #4080c0 !important;
    background-color: #4080c0 !important;
}

body.pojo-a11y-high-contrast a.button:hover,
body.pojo-a11y-high-contrast a.button:hover abbr,
body.pojo-a11y-high-contrast a.button:hover acronym,
body.pojo-a11y-high-contrast a.button:hover b,
body.pojo-a11y-high-contrast a.button:hover basefont,
body.pojo-a11y-high-contrast a.button:hover big,
body.pojo-a11y-high-contrast a.button:hover br,
body.pojo-a11y-high-contrast a.button:hover code,
body.pojo-a11y-high-contrast a.button:hover div,
body.pojo-a11y-high-contrast a.button:hover em,
body.pojo-a11y-high-contrast a.button:hover font,
body.pojo-a11y-high-contrast a.button:hover h1,
body.pojo-a11y-high-contrast a.button:hover h2,
body.pojo-a11y-high-contrast a.button:hover h3,
body.pojo-a11y-high-contrast a.button:hover h4,
body.pojo-a11y-high-contrast a.button:hover h5,
body.pojo-a11y-high-contrast a.button:hover h6,
body.pojo-a11y-high-contrast a.button:hover i,
body.pojo-a11y-high-contrast a.button:hover kbd,
body.pojo-a11y-high-contrast a.button:hover rb,
body.pojo-a11y-high-contrast a.button:hover rp,
body.pojo-a11y-high-contrast a.button:hover rt,
body.pojo-a11y-high-contrast a.button:hover ruby,
body.pojo-a11y-high-contrast a.button:hover samp,
body.pojo-a11y-high-contrast a.button:hover small,
body.pojo-a11y-high-contrast a.button:hover span,
body.pojo-a11y-high-contrast a.button:hover strong,
body.pojo-a11y-high-contrast a.button:hover tt,
body.pojo-a11y-high-contrast a.button:hover u,
body.pojo-a11y-high-contrast a.button:hover var,
body.pojo-a11y-high-contrast a:link:hover,
body.pojo-a11y-high-contrast a:link:hover abbr,
body.pojo-a11y-high-contrast a:link:hover acronym,
body.pojo-a11y-high-contrast a:link:hover b,
body.pojo-a11y-high-contrast a:link:hover basefont,
body.pojo-a11y-high-contrast a:link:hover big,
body.pojo-a11y-high-contrast a:link:hover br,
body.pojo-a11y-high-contrast a:link:hover code,
body.pojo-a11y-high-contrast a:link:hover div,
body.pojo-a11y-high-contrast a:link:hover em,
body.pojo-a11y-high-contrast a:link:hover font,
body.pojo-a11y-high-contrast a:link:hover h1,
body.pojo-a11y-high-contrast a:link:hover h2,
body.pojo-a11y-high-contrast a:link:hover h3,
body.pojo-a11y-high-contrast a:link:hover h4,
body.pojo-a11y-high-contrast a:link:hover h5,
body.pojo-a11y-high-contrast a:link:hover h6,
body.pojo-a11y-high-contrast a:link:hover i,
body.pojo-a11y-high-contrast a:link:hover kbd,
body.pojo-a11y-high-contrast a:link:hover rb,
body.pojo-a11y-high-contrast a:link:hover rp,
body.pojo-a11y-high-contrast a:link:hover rt,
body.pojo-a11y-high-contrast a:link:hover ruby,
body.pojo-a11y-high-contrast a:link:hover samp,
body.pojo-a11y-high-contrast a:link:hover small,
body.pojo-a11y-high-contrast a:link:hover span,
body.pojo-a11y-high-contrast a:link:hover strong,
body.pojo-a11y-high-contrast a:link:hover tt,
body.pojo-a11y-high-contrast a:link:hover u,
body.pojo-a11y-high-contrast a:link:hover var,
body.pojo-a11y-high-contrast a:visited:hover,
body.pojo-a11y-high-contrast a:visited:hover abbr,
body.pojo-a11y-high-contrast a:visited:hover acronym,
body.pojo-a11y-high-contrast a:visited:hover b,
body.pojo-a11y-high-contrast a:visited:hover basefont,
body.pojo-a11y-high-contrast a:visited:hover big,
body.pojo-a11y-high-contrast a:visited:hover br,
body.pojo-a11y-high-contrast a:visited:hover code,
body.pojo-a11y-high-contrast a:visited:hover div,
body.pojo-a11y-high-contrast a:visited:hover em,
body.pojo-a11y-high-contrast a:visited:hover font,
body.pojo-a11y-high-contrast a:visited:hover h1,
body.pojo-a11y-high-contrast a:visited:hover h2,
body.pojo-a11y-high-contrast a:visited:hover h3,
body.pojo-a11y-high-contrast a:visited:hover h4,
body.pojo-a11y-high-contrast a:visited:hover h5,
body.pojo-a11y-high-contrast a:visited:hover h6,
body.pojo-a11y-high-contrast a:visited:hover i,
body.pojo-a11y-high-contrast a:visited:hover kbd,
body.pojo-a11y-high-contrast a:visited:hover rb,
body.pojo-a11y-high-contrast a:visited:hover rp,
body.pojo-a11y-high-contrast a:visited:hover rt,
body.pojo-a11y-high-contrast a:visited:hover ruby,
body.pojo-a11y-high-contrast a:visited:hover samp,
body.pojo-a11y-high-contrast a:visited:hover small,
body.pojo-a11y-high-contrast a:visited:hover span,
body.pojo-a11y-high-contrast a:visited:hover strong,
body.pojo-a11y-high-contrast a:visited:hover tt,
body.pojo-a11y-high-contrast a:visited:hover u,
body.pojo-a11y-high-contrast a:visited:hover var {
    background: #400000 !important;
    background-color: #400000 !important;
}

body.pojo-a11y-high-contrast body>input#site+div#wrapper span.mk,
body.pojo-a11y-high-contrast body>input#site+div#wrapper span.mk b,
body.pojo-a11y-high-contrast input[type=reset] {
    background: #400060 !important;
    background-color: #400060 !important;
}

body.pojo-a11y-high-contrast div[role="button"],
body.pojo-a11y-high-contrast input[type=button],
body.pojo-a11y-high-contrast input[type=submit] {
    background: #600040 !important;
    background-color: #600040 !important;
}

body.pojo-a11y-high-contrast input[type=search] {
    -webkit-appearance: textfield !important;
}

body.pojo-a11y-high-contrast html button[disabled],
body.pojo-a11y-high-contrast html input[disabled],
body.pojo-a11y-high-contrast html select[disabled],
body.pojo-a11y-high-contrast html textarea[disabled] {
    background: #404040 !important;
    background-color: #404040 !important;
}

body.pojo-a11y-high-contrast .menu li a span.label {
    text-transform: none !important;
}

body.pojo-a11y-high-contrast .menu li a span.label,
body.pojo-a11y-high-contrast div.jwplayer span.jwcontrolbar,
body.pojo-a11y-high-contrast div.jwplayer span.jwcontrols {
    display: inline !important;
}

body.pojo-a11y-high-contrast a:link.new,
body.pojo-a11y-high-contrast a:link.new i,
body.pojo-a11y-high-contrast a:link.new b,
body.pojo-a11y-high-contrast span.Apple-style-span {
    color: #FFFF40 !important;
}

body.pojo-a11y-high-contrast body.mediawiki img.tex {
    background: white !important;
    background-color: white !important;
    border: white solid 3px !important;
}

body.pojo-a11y-high-contrast text>tspan:first-letter,
body.pojo-a11y-high-contrast text>tspan:first-line {
    background: inherit !important;
    background-color: inherit !important;
    color: inherit !important;
}

body.pojo-a11y-high-contrast div.sbtc div.sbsb_a li.sbsb_d div,
body.pojo-a11y-high-contrast table.gssb_c tr.gssb_i a,
body.pojo-a11y-high-contrast table.gssb_c tr.gssb_i b,
body.pojo-a11y-high-contrast table.gssb_c tr.gssb_i span,
body.pojo-a11y-high-contrast table.gssb_c tr.gssb_i td {
    background: #003050 !important;
    background-color: #003050 !important;
}

body.pojo-a11y-high-contrast img[width="18"][height="18"] {
    height: 18px !important;
    width: 18px !important;
}

body.pojo-a11y-high-contrast a>span.iconHelp:empty:after {
    content: "Help" !important;
}

body.pojo-a11y-high-contrast div#gmap,
body.pojo-a11y-high-contrast div#gmap * {
    background: initial !important;
}

body.pojo-a11y-negative-contrast,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) {
    background: #000 !important;
    color: #ffffff !important;
}

body.pojo-a11y-negative-contrast a,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) a,
body.pojo-a11y-negative-contrast b,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) b,
body.pojo-a11y-negative-contrast blockquote,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) blockquote,
body.pojo-a11y-negative-contrast button,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) button,
body.pojo-a11y-negative-contrast canvas,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) canvas,
body.pojo-a11y-negative-contrast caption,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) caption,
body.pojo-a11y-negative-contrast center,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) center,
body.pojo-a11y-negative-contrast cite,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) cite,
body.pojo-a11y-negative-contrast code,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) code,
body.pojo-a11y-negative-contrast col,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) col,
body.pojo-a11y-negative-contrast colgroup,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) colgroup,
body.pojo-a11y-negative-contrast dd,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) dd,
body.pojo-a11y-negative-contrast details,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) details,
body.pojo-a11y-negative-contrast dfn,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) dfn,
body.pojo-a11y-negative-contrast dir,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) dir,
body.pojo-a11y-negative-contrast div,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) div,
body.pojo-a11y-negative-contrast dl,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) dl,
body.pojo-a11y-negative-contrast dt,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) dt,
body.pojo-a11y-negative-contrast em,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) em,
body.pojo-a11y-negative-contrast embed,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) embed,
body.pojo-a11y-negative-contrast fieldset,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) fieldset,
body.pojo-a11y-negative-contrast figcaption,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) figcaption,
body.pojo-a11y-negative-contrast figure,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) figure,
body.pojo-a11y-negative-contrast font,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) font,
body.pojo-a11y-negative-contrast footer,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) footer,
body.pojo-a11y-negative-contrast form,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) form,
body.pojo-a11y-negative-contrast header,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) header,
body.pojo-a11y-negative-contrast i,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) i,
body.pojo-a11y-negative-contrast iframe,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) iframe,
body.pojo-a11y-negative-contrast img,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) img,
body.pojo-a11y-negative-contrast input,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) input,
body.pojo-a11y-negative-contrast kbd,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) kbd,
body.pojo-a11y-negative-contrast label,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) label,
body.pojo-a11y-negative-contrast legend,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) legend,
body.pojo-a11y-negative-contrast li,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) li,
body.pojo-a11y-negative-contrast mark,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) mark,
body.pojo-a11y-negative-contrast menu,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) menu,
body.pojo-a11y-negative-contrast meter,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) meter,
body.pojo-a11y-negative-contrast nav,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) nav,
body.pojo-a11y-negative-contrast nobr,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) nobr,
body.pojo-a11y-negative-contrast object,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) object,
body.pojo-a11y-negative-contrast ol,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) ol,
body.pojo-a11y-negative-contrast option,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) option,
body.pojo-a11y-negative-contrast pre,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) pre,
body.pojo-a11y-negative-contrast progress,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) progress,
body.pojo-a11y-negative-contrast q,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) q,
body.pojo-a11y-negative-contrast s,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) s,
body.pojo-a11y-negative-contrast section,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) section,
body.pojo-a11y-negative-contrast select,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) select,
body.pojo-a11y-negative-contrast small,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) small,
body.pojo-a11y-negative-contrast span,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) span,
body.pojo-a11y-negative-contrast strike,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) strike,
body.pojo-a11y-negative-contrast strong,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) strong,
body.pojo-a11y-negative-contrast sub,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) sub,
body.pojo-a11y-negative-contrast summary,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) summary,
body.pojo-a11y-negative-contrast sup,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) sup,
body.pojo-a11y-negative-contrast table,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) table,
body.pojo-a11y-negative-contrast td,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) td,
body.pojo-a11y-negative-contrast textarea,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) textarea,
body.pojo-a11y-negative-contrast th,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) th,
body.pojo-a11y-negative-contrast time,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) time,
body.pojo-a11y-negative-contrast tr,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) tr,
body.pojo-a11y-negative-contrast tt,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) tt,
body.pojo-a11y-negative-contrast u,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) u,
body.pojo-a11y-negative-contrast ul,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) ul,
body.pojo-a11y-negative-contrast var,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) var,
body.pojo-a11y-negative-contrast a span,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) a span,
body.pojo-a11y-negative-contrast strong,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) strong {
    color: yellow !important;
}

body.pojo-a11y-negative-contrast button,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) button,
body.pojo-a11y-negative-contrast input,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) input,
body.pojo-a11y-negative-contrast textarea,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) textarea,
body.pojo-a11y-negative-contrast select,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) select,
body.pojo-a11y-negative-contrast table,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) table,
body.pojo-a11y-negative-contrast td,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) td,
body.pojo-a11y-negative-contrast th,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) th,
body.pojo-a11y-negative-contrast tr,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) tr,
body.pojo-a11y-negative-contrast tt,
body.pojo-a11y-negative-contrast *:not(#pojo-a11y-toolbar) tt {
    border: 1px solid #ffffff !important;
}

body.pojo-a11y-negative-contrast div#gmap,
body.pojo-a11y-negative-contrast div#gmap * {
    background: initial !important;
}

body.pojo-a11y-light-background,
body.pojo-a11y-light-background *:not(#pojo-a11y-toolbar):not(.pojo-a11y-toolbar-link) {
    background: #fff !important;
    color: #000 !important;
}

body.pojo-a11y-light-background div#gmap,
body.pojo-a11y-light-background div#gmap * {
    background: initial !important;
}

body.pojo-a11y-resize-font-200,
body.pojo-a11y-resize-font-200 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-200 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-200 label,
body.pojo-a11y-resize-font-200 input,
body.pojo-a11y-resize-font-200 select,
body.pojo-a11y-resize-font-200 textarea,
body.pojo-a11y-resize-font-200 legend,
body.pojo-a11y-resize-font-200 code,
body.pojo-a11y-resize-font-200 pre,
body.pojo-a11y-resize-font-200 dd,
body.pojo-a11y-resize-font-200 dt,
body.pojo-a11y-resize-font-200 span,
body.pojo-a11y-resize-font-200 blockquote {
    font-size: 200% !important;
}

body.pojo-a11y-resize-font-200 h1,
body.pojo-a11y-resize-font-200 h2,
body.pojo-a11y-resize-font-200 h3,
body.pojo-a11y-resize-font-200 h4,
body.pojo-a11y-resize-font-200 h5,
body.pojo-a11y-resize-font-200 h6,
body.pojo-a11y-resize-font-200 h1 span,
body.pojo-a11y-resize-font-200 h2 span,
body.pojo-a11y-resize-font-200 h3 span,
body.pojo-a11y-resize-font-200 h4 span,
body.pojo-a11y-resize-font-200 h5 span,
body.pojo-a11y-resize-font-200 h6 span {
    font-size: 266% !important;
}

body.pojo-a11y-resize-font-190,
body.pojo-a11y-resize-font-190 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-190 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-190 label,
body.pojo-a11y-resize-font-190 input,
body.pojo-a11y-resize-font-190 select,
body.pojo-a11y-resize-font-190 textarea,
body.pojo-a11y-resize-font-190 legend,
body.pojo-a11y-resize-font-190 code,
body.pojo-a11y-resize-font-190 pre,
body.pojo-a11y-resize-font-190 dd,
body.pojo-a11y-resize-font-190 dt,
body.pojo-a11y-resize-font-190 span,
body.pojo-a11y-resize-font-190 blockquote {
    font-size: 190% !important;
}

body.pojo-a11y-resize-font-190 h1,
body.pojo-a11y-resize-font-190 h2,
body.pojo-a11y-resize-font-190 h3,
body.pojo-a11y-resize-font-190 h4,
body.pojo-a11y-resize-font-190 h5,
body.pojo-a11y-resize-font-190 h6,
body.pojo-a11y-resize-font-190 h1 span,
body.pojo-a11y-resize-font-190 h2 span,
body.pojo-a11y-resize-font-190 h3 span,
body.pojo-a11y-resize-font-190 h4 span,
body.pojo-a11y-resize-font-190 h5 span,
body.pojo-a11y-resize-font-190 h6 span {
    font-size: 252.70000000000002% !important;
}

body.pojo-a11y-resize-font-180,
body.pojo-a11y-resize-font-180 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-180 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-180 label,
body.pojo-a11y-resize-font-180 input,
body.pojo-a11y-resize-font-180 select,
body.pojo-a11y-resize-font-180 textarea,
body.pojo-a11y-resize-font-180 legend,
body.pojo-a11y-resize-font-180 code,
body.pojo-a11y-resize-font-180 pre,
body.pojo-a11y-resize-font-180 dd,
body.pojo-a11y-resize-font-180 dt,
body.pojo-a11y-resize-font-180 span,
body.pojo-a11y-resize-font-180 blockquote {
    font-size: 180% !important;
}

body.pojo-a11y-resize-font-180 h1,
body.pojo-a11y-resize-font-180 h2,
body.pojo-a11y-resize-font-180 h3,
body.pojo-a11y-resize-font-180 h4,
body.pojo-a11y-resize-font-180 h5,
body.pojo-a11y-resize-font-180 h6,
body.pojo-a11y-resize-font-180 h1 span,
body.pojo-a11y-resize-font-180 h2 span,
body.pojo-a11y-resize-font-180 h3 span,
body.pojo-a11y-resize-font-180 h4 span,
body.pojo-a11y-resize-font-180 h5 span,
body.pojo-a11y-resize-font-180 h6 span {
    font-size: 239.4% !important;
}

body.pojo-a11y-resize-font-170,
body.pojo-a11y-resize-font-170 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-170 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-170 label,
body.pojo-a11y-resize-font-170 input,
body.pojo-a11y-resize-font-170 select,
body.pojo-a11y-resize-font-170 textarea,
body.pojo-a11y-resize-font-170 legend,
body.pojo-a11y-resize-font-170 code,
body.pojo-a11y-resize-font-170 pre,
body.pojo-a11y-resize-font-170 dd,
body.pojo-a11y-resize-font-170 dt,
body.pojo-a11y-resize-font-170 span,
body.pojo-a11y-resize-font-170 blockquote {
    font-size: 170% !important;
}

body.pojo-a11y-resize-font-170 h1,
body.pojo-a11y-resize-font-170 h2,
body.pojo-a11y-resize-font-170 h3,
body.pojo-a11y-resize-font-170 h4,
body.pojo-a11y-resize-font-170 h5,
body.pojo-a11y-resize-font-170 h6,
body.pojo-a11y-resize-font-170 h1 span,
body.pojo-a11y-resize-font-170 h2 span,
body.pojo-a11y-resize-font-170 h3 span,
body.pojo-a11y-resize-font-170 h4 span,
body.pojo-a11y-resize-font-170 h5 span,
body.pojo-a11y-resize-font-170 h6 span {
    font-size: 226.10000000000002% !important;
}

body.pojo-a11y-resize-font-160,
body.pojo-a11y-resize-font-160 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-160 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-160 label,
body.pojo-a11y-resize-font-160 input,
body.pojo-a11y-resize-font-160 select,
body.pojo-a11y-resize-font-160 textarea,
body.pojo-a11y-resize-font-160 legend,
body.pojo-a11y-resize-font-160 code,
body.pojo-a11y-resize-font-160 pre,
body.pojo-a11y-resize-font-160 dd,
body.pojo-a11y-resize-font-160 dt,
body.pojo-a11y-resize-font-160 span,
body.pojo-a11y-resize-font-160 blockquote {
    font-size: 160% !important;
}

body.pojo-a11y-resize-font-160 h1,
body.pojo-a11y-resize-font-160 h2,
body.pojo-a11y-resize-font-160 h3,
body.pojo-a11y-resize-font-160 h4,
body.pojo-a11y-resize-font-160 h5,
body.pojo-a11y-resize-font-160 h6,
body.pojo-a11y-resize-font-160 h1 span,
body.pojo-a11y-resize-font-160 h2 span,
body.pojo-a11y-resize-font-160 h3 span,
body.pojo-a11y-resize-font-160 h4 span,
body.pojo-a11y-resize-font-160 h5 span,
body.pojo-a11y-resize-font-160 h6 span {
    font-size: 212.8% !important;
}

body.pojo-a11y-resize-font-150,
body.pojo-a11y-resize-font-150 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-150 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-150 label,
body.pojo-a11y-resize-font-150 input,
body.pojo-a11y-resize-font-150 select,
body.pojo-a11y-resize-font-150 textarea,
body.pojo-a11y-resize-font-150 legend,
body.pojo-a11y-resize-font-150 code,
body.pojo-a11y-resize-font-150 pre,
body.pojo-a11y-resize-font-150 dd,
body.pojo-a11y-resize-font-150 dt,
body.pojo-a11y-resize-font-150 span,
body.pojo-a11y-resize-font-150 blockquote {
    font-size: 150% !important;
}

body.pojo-a11y-resize-font-150 h1,
body.pojo-a11y-resize-font-150 h2,
body.pojo-a11y-resize-font-150 h3,
body.pojo-a11y-resize-font-150 h4,
body.pojo-a11y-resize-font-150 h5,
body.pojo-a11y-resize-font-150 h6,
body.pojo-a11y-resize-font-150 h1 span,
body.pojo-a11y-resize-font-150 h2 span,
body.pojo-a11y-resize-font-150 h3 span,
body.pojo-a11y-resize-font-150 h4 span,
body.pojo-a11y-resize-font-150 h5 span,
body.pojo-a11y-resize-font-150 h6 span {
    font-size: 199.5% !important;
}

body.pojo-a11y-resize-font-140,
body.pojo-a11y-resize-font-140 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-140 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-140 label,
body.pojo-a11y-resize-font-140 input,
body.pojo-a11y-resize-font-140 select,
body.pojo-a11y-resize-font-140 textarea,
body.pojo-a11y-resize-font-140 legend,
body.pojo-a11y-resize-font-140 code,
body.pojo-a11y-resize-font-140 pre,
body.pojo-a11y-resize-font-140 dd,
body.pojo-a11y-resize-font-140 dt,
body.pojo-a11y-resize-font-140 span,
body.pojo-a11y-resize-font-140 blockquote {
    font-size: 140% !important;
}

body.pojo-a11y-resize-font-140 h1,
body.pojo-a11y-resize-font-140 h2,
body.pojo-a11y-resize-font-140 h3,
body.pojo-a11y-resize-font-140 h4,
body.pojo-a11y-resize-font-140 h5,
body.pojo-a11y-resize-font-140 h6,
body.pojo-a11y-resize-font-140 h1 span,
body.pojo-a11y-resize-font-140 h2 span,
body.pojo-a11y-resize-font-140 h3 span,
body.pojo-a11y-resize-font-140 h4 span,
body.pojo-a11y-resize-font-140 h5 span,
body.pojo-a11y-resize-font-140 h6 span {
    font-size: 186.20000000000002% !important;
}

body.pojo-a11y-resize-font-130,
body.pojo-a11y-resize-font-130 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-130 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-130 label,
body.pojo-a11y-resize-font-130 input,
body.pojo-a11y-resize-font-130 select,
body.pojo-a11y-resize-font-130 textarea,
body.pojo-a11y-resize-font-130 legend,
body.pojo-a11y-resize-font-130 code,
body.pojo-a11y-resize-font-130 pre,
body.pojo-a11y-resize-font-130 dd,
body.pojo-a11y-resize-font-130 dt,
body.pojo-a11y-resize-font-130 span,
body.pojo-a11y-resize-font-130 blockquote {
    font-size: 130% !important;
}

body.pojo-a11y-resize-font-130 h1,
body.pojo-a11y-resize-font-130 h2,
body.pojo-a11y-resize-font-130 h3,
body.pojo-a11y-resize-font-130 h4,
body.pojo-a11y-resize-font-130 h5,
body.pojo-a11y-resize-font-130 h6,
body.pojo-a11y-resize-font-130 h1 span,
body.pojo-a11y-resize-font-130 h2 span,
body.pojo-a11y-resize-font-130 h3 span,
body.pojo-a11y-resize-font-130 h4 span,
body.pojo-a11y-resize-font-130 h5 span,
body.pojo-a11y-resize-font-130 h6 span {
    font-size: 172.9% !important;
}

body.pojo-a11y-resize-font-120,
body.pojo-a11y-resize-font-120 p:not(.pojo-a11y-toolbar-title),
body.pojo-a11y-resize-font-120 li:not(.pojo-a11y-toolbar-item),
body.pojo-a11y-resize-font-120 label,
body.pojo-a11y-resize-font-120 input,
body.pojo-a11y-resize-font-120 select,
body.pojo-a11y-resize-font-120 textarea,
body.pojo-a11y-resize-font-120 legend,
body.pojo-a11y-resize-font-120 code,
body.pojo-a11y-resize-font-120 pre,
body.pojo-a11y-resize-font-120 dd,
body.pojo-a11y-resize-font-120 dt,
body.pojo-a11y-resize-font-120 span,
body.pojo-a11y-resize-font-120 blockquote {
    font-size: 120% !important;
}

body.pojo-a11y-resize-font-120 h1,
body.pojo-a11y-resize-font-120 h2,
body.pojo-a11y-resize-font-120 h3,
body.pojo-a11y-resize-font-120 h4,
body.pojo-a11y-resize-font-120 h5,
body.pojo-a11y-resize-font-120 h6,
body.pojo-a11y-resize-font-120 h1 span,
body.pojo-a11y-resize-font-120 h2 span,
body.pojo-a11y-resize-font-120 h3 span,
body.pojo-a11y-resize-font-120 h4 span,
body.pojo-a11y-resize-font-120 h5 span,
body.pojo-a11y-resize-font-120 h6 span {
    font-size: 159.60000000000002% !important;
}

body.pojo-a11y-links-underline {
    /* Temporarily checking selectors
a[onclick] , a[onclick] abbr, a[onclick] acronym, a[onclick] b,
a[onclick] basefont, a[onclick] big, a[onclick] br, a[onclick] code,
a[onclick] div, a[onclick] em, a[onclick] font, a[onclick] h1,
a[onclick] h2, a[onclick] h3, a[onclick] h4, a[onclick] h5, a[onclick]
h6, a[onclick] i, a[onclick] kbd, a[onclick] small, a[onclick] span,
a[onclick] strong, a[onclick] tt, a[onclick] u, a[onclick] var,
a[onclick]:active , a[onclick]:active abbr, a[onclick]:active acronym,
a[onclick]:active b, a[onclick]:active basefont, a[onclick]:active
big, a[onclick]:active br, a[onclick]:active code, a[onclick]:active
div, a[onclick]:active em, a[onclick]:active font, a[onclick]:active
h1, a[onclick]:active h2, a[onclick]:active h3, a[onclick]:active h4,
a[onclick]:active h5, a[onclick]:active h6, a[onclick]:active i,
a[onclick]:active kbd, a[onclick]:active small, a[onclick]:active
span, a[onclick]:active strong, a[onclick]:active tt,
a[onclick]:active u, a[onclick]:active var {
	text-decoration: underline !important;
}
a:link , a:link abbr, a:link acronym, a:link b, a:link basefont,
a:link big, a:link br, a:link code, a:link div, a:link em, a:link
font, a:link h1, a:link h2, a:link h3, a:link h4, a:link h5, a:link
h6, a:link i, a:link kbd, a:link small, a:link span, a:link strong,
a:link tt, a:link u, a:link var, a:link:active , a:link:active abbr,
a:link:active acronym, a:link:active b, a:link:active basefont,
a:link:active big, a:link:active br, a:link:active code, a:link:active
div, a:link:active em, a:link:active font, a:link:active h1,
a:link:active h2, a:link:active h3, a:link:active h4, a:link:active
h5, a:link:active h6, a:link:active i, a:link:active kbd,
a:link:active small, a:link:active span, a:link:active strong,
a:link:active tt, a:link:active u, a:link:active var, a:visited ,
a:visited abbr, a:visited acronym, a:visited b, a:visited basefont,
a:visited big, a:visited br, a:visited code, a:visited div, a:visited
em, a:visited font, a:visited h1, a:visited h2, a:visited h3,
a:visited h4, a:visited h5, a:visited h6, a:visited i, a:visited kbd,
a:visited small, a:visited span, a:visited strong, a:visited tt,
a:visited u, a:visited var, a:visited:active , a:visited:active abbr,
a:visited:active acronym, a:visited:active b, a:visited:active
basefont, a:visited:active big, a:visited:active br, a:visited:active
code, a:visited:active div, a:visited:active em, a:visited:active
font, a:visited:active h1, a:visited:active h2, a:visited:active h3,
a:visited:active h4, a:visited:active h5, a:visited:active h6,
a:visited:active i, a:visited:active kbd, a:visited:active small,
a:visited:active span, a:visited:active strong, a:visited:active tt,
a:visited:active u, a:visited:active var {
	text-decoration: underline !important;
}*/
}

body.pojo-a11y-links-underline a,
body.pojo-a11y-links-underline a * {
    text-decoration: underline !important;
}

body.pojo-a11y-readable-font,
body.pojo-a11y-readable-font *:not(i):not(span) {
    font-family: Verdana, Arial, Helvetica, sans-serif !important;
}

.pojo-skip-link {
    position: absolute;
    top: -1000em;
}

.pojo-skip-link:focus {
    left: 50px;
    top: 50px;
    height: auto;
    width: 200px;
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 15px 23px 14px;
    background-color: #F1F1F1;
    color: #21759B;
    z-index: 100000;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
}

body.rtl .pojo-skip-link:focus {
    right: 50px;
    left: auto;
}

#pojo-a11y-toolbar *,
#pojo-a11y-toolbar ::before,
#pojo-a11y-toolbar ::after {
    box-sizing: border-box;
}

.pojo-a11y-visible-phone {
    display: none !important;
}

.pojo-a11y-visible-tablet {
    display: none !important;
}

.pojo-a11y-hidden-desktop {
    display: none !important;
}

.pojo-a11y-visible-desktop {
    display: inherit !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .pojo-a11y-hidden-desktop {
        display: inherit !important;
    }

    .pojo-a11y-visible-desktop {
        display: none !important;
    }

    .pojo-a11y-visible-tablet {
        display: inherit !important;
    }

    .pojo-a11y-hidden-tablet {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .pojo-a11y-hidden-desktop {
        display: inherit !important;
    }

    .pojo-a11y-visible-desktop {
        display: none !important;
    }

    .pojo-a11y-visible-phone {
        display: inherit !important;
    }

    /* Use inherit to restore previous behavior */
    .pojo-a11y-hidden-phone {
        display: none !important;
    }
}

.pojo-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}